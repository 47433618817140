<template>
  <div>
    <KTCard title="Trainee User Pre Registration List">
      <template v-slot:toolbar>
        <b-button
          @click="openModal('TraineeUserPreRegistrationModal')"
          variant="primary"
          size="sm"
          ><i class="fa fa-plus"></i> Add New Trainee</b-button
        >
      </template>

      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          hover
          show-empty
          :items="tableItems"
          :fields="fields"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="onApprove(row.item)"
                variant="primary"
                size="sm"
              >
                Approve
              </b-button>
              <b-button @click="onReject(row.item)" variant="danger" size="sm">
                Reject
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
  APPROVE_TRAINEE_USER_REGISTRAION,
  GET_TEMP_TRAINEE_USER_PRE_REG_LIST,
  REJECT_TRAINEE_USER_REGISTRAION,
} from "@/core/services/store/modules/clientadmin/trainee.module";

export default {
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Trainee User Pre Registration" },
    ]);
    this.$store.dispatch(
      GET_TEMP_TRAINEE_USER_PRE_REG_LIST,
      this.defaultPayload
    );
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.clientadmin_trainee.traineeUserPreRegList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    tableItems() {
      const list = this.$store.state.clientadmin_trainee.traineeUserPreRegList;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      filter: "",
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
        },
        {
          key: "trainee_username",
          label: "Trainee Username",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onApprove(user) {
      let payload = {
        ref_id: user.ref_id,
      };
      this.$store
        .dispatch(APPROVE_TRAINEE_USER_REGISTRAION, payload)
        .then((resp) => {
          if (resp.response_code === 2100) {
            console.log("APPROVE_TRAINEE_USER_REGISTRAION SUCCESS");
          } else {
            console.log("APPROVE_TRAINEE_USER_REGISTRAION FAILED");
          }
        });
    },
    onReject(user) {
      let payload = {
        ref_id: user.ref_id,
      };
      this.$store
        .dispatch(REJECT_TRAINEE_USER_REGISTRAION, payload)
        .then((resp) => {
          if (resp.response_code === 2100) {
            console.log("REJECT_TRAINEE_USER_REGISTRAION SUCCESS");
          } else {
            console.log("REJECT_TRAINEE_USER_REGISTRAION FAILED");
          }
        });
    },
  },
};
</script>
